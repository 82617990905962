/*-----------Types of ABOUT actions--------*/

/*---SAVE----*/
export const APPOINTMENT_SAVE_SUCCESS = "APPOINTMENT_SAVE_SUCCESS";
export const APPOINTMENT_SAVE_FAILED = "APPOINTMENT_SAVE_FAILED";
export const APPOINTMENT_IS_SAVING = "APPOINTMENT_IS_SAVING";
/*---FETCH----*/
export const APPOINTMENT_FETCH_SUCCESS = "APPOINTMENT_FETCH_SUCCESS";
export const ONE_APPOINTMENT_FETCH_SUCCESS = "One_APPOINTMENT_FETCH_SUCCESS";
export const APPOINTMENT_FETCH_FAILED = "APPOINTMENT_FETCH_FAILED";
export const APPOINTMENT_IS_FETCHING = "APPOINTMENT_IS_FETCHING";
/*---UPDATE----*/
export const APPOINTMENT_UPDATE_SUCCESS = "APPOINTMENT_UPDATE_SUCCESS";
export const APPOINTMENT_UPDATE_FAILED = "APPOINTMENT_UPDATE_FAILED";
export const APPOINTMENT_IS_UPDATING = "APPOINTMENT_IS_UPDATING";

/*---PATCH----*/
export const APPOINTMENT_PATCH_SUCCESS = "APPOINTMENT_PATCH_SUCCESS";
export const APPOINTMENT_PATCH_FAILED = "APPOINTMENT_PATCH_FAILED";
export const APPOINTMENT_IS_PATCHING = "APPOINTMENT_IS_PATCHING";


/*---DELETE----*/
export const APPOINTMENT_DELETE_SUCCESS = "APPOINTMENT_DELETE_SUCCESS";
export const APPOINTMENT_DELETE_FAILED = "APPOINTMENT_DELETE_FAILED";
export const APPOINTMENT_IS_DELETING = "APPOINTMENT_IS_DELETING";
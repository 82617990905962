/*-----------Types of FROMATION actions--------*/

/*---SAVE----*/
export const FORMATION_SAVE_SUCCESS = "FORMATION_SAVE_SUCCESS";
export const FORMATION_SAVE_FAILED = "FORMATION_SAVE_FAILED";
export const FORMATION_IS_SAVING = "FORMATION_IS_SAVING";
/*---FETCH----*/
export const FORMATION_FETCH_SUCCESS = "FORMATION_FETCH_SUCCESS";
export const FORMATION_FETCH_FAILED = "FORMATION_FETCH_FAILED";
export const FORMATION_IS_FETCHING = "FORMATION_IS_FETCHING";

/*---UPDATE----*/
export const FORMATION_UPDATE_SUCCESS = "FORMATION_UPDATE_SUCCESS";
export const FORMATION_UPDATE_FAILED = "FORMATION_UPDATE_FAILED";
export const FORMATION_IS_UPDATING = "FORMATION_IS_UPDATING";

/*---PATCH----*/
export const FORMATION_PATCH_SUCCESS = "FORMATION_PATCH_SUCCESS";
export const FORMATION_PATCH_FAILED = "FORMATION_PATCH_FAILED";
export const FORMATION_IS_PATCHING = "FORMATION_IS_PATCHING";


/*---DELETE----*/
export const FORMATION_DELETE_SUCCESS = "FORMATION_DELETE_SUCCESS";
export const FORMATION_DELETE_FAILED = "FORMATION_DELETE_FAILED";
export const FORMATION_IS_DELETING = "FORMATION_IS_DELETING";
/*-----------Types of VIDEOS actions--------*/

/*---SAVE----*/
export const VIDEO_SAVE_SUCCESS = "VIDEO_SAVE_SUCCESS";
export const VIDEO_SAVE_FAILED = "VIDEO_SAVE_FAILED";
export const VIDEO_IS_SAVING = "VIDEO_IS_SAVING";
/*---FETCH----*/
export const VIDEO_FETCH_SUCCESS = "VIDEO_FETCH_SUCCESS";
export const ONE_VIDEO_FETCH_SUCCESS = "One_VIDEO_FETCH_SUCCESS";
export const VIDEO_FETCH_FAILED = "VIDEO_FETCH_FAILED";
export const VIDEO_IS_FETCHING = "VIDEO_IS_FETCHING";
/*---UPDATE----*/
export const VIDEO_UPDATE_SUCCESS = "VIDEO_UPDATE_SUCCESS";
export const VIDEO_UPDATE_FAILED = "VIDEO_UPDATE_FAILED";
export const VIDEO_IS_UPDATING = "VIDEO_IS_UPDATING";

/*---PATCH----*/
export const VIDEO_PATCH_SUCCESS = "VIDEO_PATCH_SUCCESS";
export const VIDEO_PATCH_FAILED = "VIDEO_PATCH_FAILED";
export const VIDEO_IS_PATCHING = "VIDEO_IS_PATCHING";


/*---DELETE----*/
export const VIDEO_DELETE_SUCCESS = "VIDEO_DELETE_SUCCESS";
export const VIDEO_DELETE_FAILED = "VIDEO_DELETE_FAILED";
export const VIDEO_IS_DELETING = "VIDEO_IS_DELETING";
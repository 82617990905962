import authReducer from "./authReducer";

import usersReducer from "./usersReducer";
import aboutReducer from "./aboutReducer";
import goalsReducer from "./goalsReducer";
import filesReducer from "./uploadedFiles";
import alertReducer from "./alert";

import diplomaReducer from "./diplomaReducer";

import bookReducer from "./bookReducer";

import formationReducer from "./formationReducer";

import appointmentReducer from "./appointmentReducer";
import smsReducer from "./smsReducer";
import messageReducer from "./messageReducer";
import emailReducer from "./emailReducer";
import notificationReducer from "./notificationReducer";
import fileUploadReducer from "./fileUploadReducer";
import videosReducer from "./videosReducer";

import { combineReducers } from "redux";

export default combineReducers({
  aboutReducer,
  authReducer,
  usersReducer,
  filesReducer,
  goalsReducer,
  alertReducer,
 
  appointmentReducer,
  smsReducer,
  emailReducer,
  messageReducer,
  notificationReducer,
  fileUploadReducer,
  videosReducer,
  diplomaReducer,
  formationReducer,
  
  bookReducer,
 
  
});

import {
  MESSAGE_SAVE_FAILED,
  MESSAGE_SAVE_SUCCESS,
  MESSAGE_IS_SAVING,
  MESSAGE_DELETE_FAILED,
  MESSAGE_DELETE_SUCCESS,
  MESSAGE_IS_DELETING,
  MESSAGE_FETCH_FAILED,
  MESSAGE_FETCH_SUCCESS,
  ONE_MESSAGE_FETCH_SUCCESS,
  MESSAGE_IS_FETCHING,
  MESSAGE_IS_UPDATING,
  MESSAGE_UPDATE_FAILED,
  MESSAGE_UPDATE_SUCCESS
  // GET_SEARCH
} from "../types/messageTypes";

var initialState = {
  messageSaved: false,
  messageIsSaving: false,
  messageIsFetching: false,
  messageDeleted: false,
  messageIsDeleting: false,
  messageUpdated: false,
  messageIsUpdating: false,
  messages: [],
  message: {},
  error: null
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    //-------Save-------//
    case MESSAGE_IS_SAVING:
      return {
        ...state,
        messageIsSaving: true
      };
    case MESSAGE_SAVE_SUCCESS:
      return {
        ...state,
        messageSaved: true,
        messageIsSaving: false,
        error: action.payload
      };
    case MESSAGE_SAVE_FAILED:
      return {
        ...state,
        messageIsSaving: false
      };

    //-------Fetch-------//
    case MESSAGE_IS_FETCHING:
      return { ...state, messageIsFetching: true };
    case MESSAGE_FETCH_SUCCESS:
      return {
        ...state,
        messageIsFetching: false,
        messages: action.payload
      };
    case ONE_MESSAGE_FETCH_SUCCESS:
      return {
        ...state,
        messageIsFetching: false,
        message: action.payload,
        error: null
      };
    case MESSAGE_FETCH_FAILED:
      return {
        ...state,
        messageIsFetching: false,
        error: action.payload
      };

    //-------update-------//
    case MESSAGE_IS_UPDATING:
      return { ...state, messageIsUpdating: true };
    case MESSAGE_UPDATE_SUCCESS:
      return {
        ...state,
        messageUpdated: true,
        messageIsUpdating: false
      };
    case MESSAGE_UPDATE_FAILED:
      return {
        ...state,
        messageIsUpdating: false,
        error: action.payload
      };

    //-------Delete-------//
    case MESSAGE_IS_DELETING:
      return { ...state, messageIsDeleting: true };
    case MESSAGE_DELETE_SUCCESS:
      return {
        ...state,
        messageDeleted: true,
        messageIsDeleting: false
      };
    case MESSAGE_DELETE_FAILED:
      return {
        ...state,
        messageIsDeleting: false,
        error: action.payload
      };

    //----DEFAULT----//
    default:
      return state;
  }
};
export default messageReducer;

import {
  //SAVE
    FORMATION_SAVE_FAILED,
    FORMATION_SAVE_SUCCESS,
    FORMATION_IS_SAVING,
    //LOAD
    FORMATION_FETCH_FAILED,
    FORMATION_FETCH_SUCCESS,
    FORMATION_IS_FETCHING,
    //UPDATE
    FORMATION_IS_UPDATING,
    FORMATION_UPDATE_FAILED,
    FORMATION_UPDATE_SUCCESS,
    //DELETE
    FORMATION_DELETE_SUCCESS,
    FORMATION_DELETE_FAILED,
    FORMATION_IS_DELETING,
    //PATCH
    FORMATION_IS_PATCHING,
    FORMATION_PATCH_FAILED,
    FORMATION_PATCH_SUCCESS
   
  } from "../types/formationTypes";
  
  var initialState = {
    formationSaved: false,
    formationIsSaving: false,
    formationIsFetching: false,
    formationUpdated: false,
    formationIsUpdating: false,
    formationPatched: false,
    formationIsPatching: false,
    formationDeleted: false,
    formationIsDeleting: false,
    error: null,
    formations: [],
    formation:{}
  };
  
  const formationReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case FORMATION_IS_SAVING:
        return {
          ...state,
          formationIsSaving: true,
          error: null
        };
      case FORMATION_SAVE_SUCCESS:
        return {
          ...state,
          formationSaved: true,
          formationIsSaving: false,
          error: null,
          formation:action.payload
        };
      case FORMATION_SAVE_FAILED:
        return {
          ...state,
          formationIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case FORMATION_IS_FETCHING:
        return { ...state,
          formationIsFetching: true,
          error: null };
      case FORMATION_FETCH_SUCCESS:
        return {
          ...state,
          formationIsFetching: false,
          formations: action.payload,
          error: null
        };
      case FORMATION_FETCH_FAILED:
        return {
          ...state,
          formationIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case FORMATION_IS_UPDATING:
        return { ...state, formationIsUpdating: true,error: null };
      case FORMATION_UPDATE_SUCCESS:
        return {
          ...state,
          formationUpdated: true,
          formationIsUpdating: false,
          error: null
        };
      case FORMATION_UPDATE_FAILED:
        return {
          ...state,
          formationIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case FORMATION_IS_PATCHING:
        return { ...state, formationIsPatching: true,error: null };
      case FORMATION_PATCH_SUCCESS:
        return {
          ...state,
          formationPatched: true,
          formationIsPatching: false,
          error: null
        };
      case FORMATION_PATCH_FAILED:
        return {
          ...state,
          formationIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case FORMATION_IS_DELETING:
      return { ...state, formationIsDeleting: true ,error: null};
    case FORMATION_DELETE_SUCCESS:
      return {
        ...state,
        formationDeleted: true,
        formationIsDeleting: false,
        error: null
      };
    case FORMATION_DELETE_FAILED:
      return {
        ...state,
        formationIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default formationReducer;
  
  
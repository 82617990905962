import {
   ADD_FILES
  } from "../types/usersTypes";
  
  var initialState = {
    files:[],
    oneFile:null,
    
    };
  
  const FilesReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Fetch-------//
        case "ADD_FILES":
        return { ...state,files:[...state.files,...action.payload]};
        case "ADD_ONE_FILE":
        return { ...state,oneFile:action.payload};
        case "CLEAR_STATE_ONE_FILE":
        return {...state,oneFile:null,files:[]}
      //----DEFAULT----//
      default:
        return state;
    }
  };
  export default FilesReducer;
  
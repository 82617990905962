/*-----------Types of NOTIFICATION actions--------*/
/*---SAVE----*/
export const NOTIFICATION_SAVE_SUCCESS = "NOTIFICATION_SAVE_SUCCESS";
export const NOTIFICATION_SAVE_FAILED = "NOTIFICATION_SAVE_FAILED";
export const NOTIFICATION_IS_SAVING = "NOTIFICATION_IS_SAVING";
/*---STOP----*/
export const NOTIFICATION_STOP_SUCCESS = "NOTIFICATION_STOP_SUCCESS";
export const NOTIFICATION_STOP_FAILED = "NOTIFICATION_STOP_FAILED";
export const NOTIFICATION_IS_STOPPING = "NOTIFICATION_IS_STOPPING";
/*---FETCH----*/
export const NOTIFICATION_FETCH_SUCCESS = "NOTIFICATION_FETCH_SUCCESS";
export const NOTIFICATION_FETCH_FAILED = "NOTIFICATION_FETCH_FAILED";
export const NOTIFICATION_IS_FETCHING = "NOTIFICATION_IS_FETCHING";
export const ONE_NOTIFICATION_FETCH_SUCCESS = "ONE_NOTIFICATION_FETCH_SUCCESS";
/*---UPDATE----*/
export const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
export const NOTIFICATION_UPDATE_FAILED = "NOTIFICATION_UPDATE_FAILED";
export const NOTIFICATION_IS_UPDATING = "NOTIFICATION_IS_UPDATING";
/*---DELETE----*/
export const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
export const NOTIFICATION_DELETE_FAILED = "NOTIFICATION_DELETE_FAILED";
export const NOTIFICATION_IS_DELETING = "NOTIFICATION_IS_DELETING";

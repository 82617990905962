/*-----------Types of EVENT actions--------*/

/*---SAVE----*/
export const DIPLOMA_SAVE_SUCCESS = "DIPLOMA_SAVE_SUCCESS";
export const DIPLOMA_SAVE_FAILED = "DIPLOMA_SAVE_FAILED";
export const DIPLOMA_IS_SAVING = "DIPLOMA_IS_SAVING";
/*---FETCH----*/
export const DIPLOMA_FETCH_SUCCESS = "DIPLOMA_FETCH_SUCCESS";
export const DIPLOMA_FETCH_FAILED = "DIPLOMA_FETCH_FAILED";
export const DIPLOMA_IS_FETCHING = "DIPLOMA_IS_FETCHING";

/*---UPDATE----*/
export const DIPLOMA_UPDATE_SUCCESS = "DIPLOMA_UPDATE_SUCCESS";
export const DIPLOMA_UPDATE_FAILED = "DIPLOMA_UPDATE_FAILED";
export const DIPLOMA_IS_UPDATING = "DIPLOMA_IS_UPDATING";

/*---PATCH----*/
export const DIPLOMA_PATCH_SUCCESS = "DIPLOMA_PATCH_SUCCESS";
export const DIPLOMA_PATCH_FAILED = "DIPLOMA_PATCH_FAILED";
export const DIPLOMA_IS_PATCHING = "DIPLOMA_IS_PATCHING";


/*---DELETE----*/
export const DIPLOMA_DELETE_SUCCESS = "DIPLOMA_DELETE_SUCCESS";
export const DIPLOMA_DELETE_FAILED = "DIPLOMA_DELETE_FAILED";
export const DIPLOMA_IS_DELETING = "DIPLOMA_IS_DELETING";
import {
  //SAVE
    BOOK_SAVE_FAILED,
    BOOK_SAVE_SUCCESS,
    BOOK_IS_SAVING,
    //LOAD
    BOOK_FETCH_FAILED,
    BOOK_FETCH_SUCCESS,
    BOOK_IS_FETCHING,
    //UPDATE
    BOOK_IS_UPDATING,
    BOOK_UPDATE_FAILED,
    BOOK_UPDATE_SUCCESS,
    //DELETE
    BOOK_DELETE_SUCCESS,
    BOOK_DELETE_FAILED,
    BOOK_IS_DELETING,
    //PATCH
    BOOK_IS_PATCHING,
    BOOK_PATCH_FAILED,
    BOOK_PATCH_SUCCESS
   
  } from "../types/bookTypes";
  
  var initialState = {
    bookSaved: false,
    bookIsSaving: false,
    bookIsFetching: false,
    bookUpdated: false,
    bookIsUpdating: false,
    bookPatched: false,
    bookIsPatching: false,
    bookDeleted: false,
    bookIsDeleting: false,
    error: null,
    books: [],
    book:{}
  };
  
  const bookReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case BOOK_IS_SAVING:
        return {
          ...state,
          bookIsSaving: true,
          error: null
        };
      case BOOK_SAVE_SUCCESS:
        return {
          ...state,
          bookSaved: true,
          bookIsSaving: false,
          error: null,
          book:action.payload
        };
      case BOOK_SAVE_FAILED:
        return {
          ...state,
          bookIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case BOOK_IS_FETCHING:
        return { ...state,
          bookIsFetching: true,
          error: null };
      case BOOK_FETCH_SUCCESS:
        return {
          ...state,
          bookIsFetching: false,
          books: action.payload,
          error: null
        };
      case BOOK_FETCH_FAILED:
        return {
          ...state,
          bookIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case BOOK_IS_UPDATING:
        return { ...state, bookIsUpdating: true,error: null };
      case BOOK_UPDATE_SUCCESS:
        return {
          ...state,
          bookUpdated: true,
          bookIsUpdating: false,
          error: null
        };
      case BOOK_UPDATE_FAILED:
        return {
          ...state,
          bookIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case BOOK_IS_PATCHING:
        return { ...state, bookIsPatching: true,error: null };
      case BOOK_PATCH_SUCCESS:
        return {
          ...state,
          bookPatched: true,
          bookIsPatching: false,
          error: null
        };
      case BOOK_PATCH_FAILED:
        return {
          ...state,
          bookIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case BOOK_IS_DELETING:
      return { ...state, bookIsDeleting: true ,error: null};
    case BOOK_DELETE_SUCCESS:
      return {
        ...state,
        bookDeleted: true,
        bookIsDeleting: false,
        error: null
      };
    case BOOK_DELETE_FAILED:
      return {
        ...state,
        bookIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default bookReducer;
  
  
import {
  EMAIL_SAVE_FAILED,
  EMAIL_SAVE_SUCCESS,
  EMAIL_IS_SAVING,
  EMAIL_DELETE_FAILED,
  EMAIL_DELETE_SUCCESS,
  EMAIL_IS_DELETING,
  EMAIL_FETCH_FAILED,
  EMAIL_FETCH_SUCCESS,
  EMAIL_IS_FETCHING,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAILED,
  EMAIL_IS_SENDING
} from "../types/emailTypes";

var initialState = {
  emailSent: false,
  emailIsSending: false,
  emailSaved: false,
  emailIsSaving: false,
  emailIsFetching: false,
  emailDeleted: false,
  emailIsDeleting: false,
  emailUpdated: false,
  emailIsUpdating: false,
  emails: [],
  error: null
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    //-------Save-------//
    case EMAIL_IS_SAVING:
      return {
        ...state,
        emailIsSaving: true
      };
    case EMAIL_SAVE_SUCCESS:
      return {
        ...state,
        emailSaved: true,
        emailIsSaving: false
      };
    case EMAIL_SAVE_FAILED:
      return {
        ...state,
        emailIsSaving: false
      };

    //-------Fetch-------//
    case EMAIL_IS_FETCHING:
      return { ...state, emailIsFetching: true };
    case EMAIL_FETCH_SUCCESS:
      return {
        ...state,
        emailIsFetching: false,
        emails: action.payload,
        error: null
      };
    case EMAIL_FETCH_FAILED:
      return {
        ...state,
        emailIsFetching: false
      };

    //-------Delete-------//
    case EMAIL_IS_DELETING:
      return { ...state, emailIsDeleting: true };
    case EMAIL_DELETE_SUCCESS:
      return {
        ...state,
        emailtDeleted: true,
        emailIsDeleting: false
      };
    case EMAIL_DELETE_FAILED:
      return {
        ...state,
        emailIsDeleting: false
      };

    //-------Send Email-------//
    case EMAIL_IS_SENDING:
      return { ...state, emailIsSending: true };
    case EMAIL_SEND_SUCCESS:
      return {
        ...state,
        emailSent: true,
        emailIsSending: false
      };
    case EMAIL_SEND_FAILED:
      return {
        ...state,
        emailIsSending: false,
        error: action.payload
      };

    //----DEFAULT----//
    default:
      return state;
  }
};
export default emailReducer;

/*-----------Types of EMAIL actions--------*/
/*---SEND MAIL----*/
export const EMAIL_SEND_SUCCESS = "EMAIL_SEND_SUCCESS";
export const EMAIL_SEND_FAILED = "EMAIL_SEND_FAILED";
export const EMAIL_IS_SENDING = "EMAIL_IS_SENDING";
/*---SAVE----*/
export const EMAIL_SAVE_SUCCESS = "EMAIL_SAVE_SUCCESS";
export const EMAIL_SAVE_FAILED = "EMAIL_SAVE_FAILED";
export const EMAIL_IS_SAVING = "EMAIL_IS_SAVING";
/*---FETCH----*/
export const EMAIL_FETCH_SUCCESS = "EMAIL_FETCH_SUCCESS";
export const EMAIL_FETCH_FAILED = "EMAIL_FETCH_FAILED";
export const EMAIL_IS_FETCHING = "EMAIL_IS_FETCHING";
/*---DELETE----*/
export const EMAIL_DELETE_SUCCESS = "EMAIL_DELETE_SUCCESS";
export const EMAIL_DELETE_FAILED = "EMAIL_DELETE_FAILED";
export const EMAIL_IS_DELETING = "EMAIL_IS_DELETING";
import {
   //SAVE
   APPOINTMENT_SAVE_FAILED,
   APPOINTMENT_SAVE_SUCCESS,
   APPOINTMENT_IS_SAVING,
   //LOAD
   APPOINTMENT_FETCH_FAILED,
   APPOINTMENT_FETCH_SUCCESS,
   ONE_APPOINTMENT_FETCH_SUCCESS,
   APPOINTMENT_IS_FETCHING,
   //UPDATE
   APPOINTMENT_IS_UPDATING,
   APPOINTMENT_UPDATE_FAILED,
   APPOINTMENT_UPDATE_SUCCESS,
   //DELETE
   APPOINTMENT_DELETE_SUCCESS,
   APPOINTMENT_DELETE_FAILED,
   APPOINTMENT_IS_DELETING,
   //PATCH
   APPOINTMENT_IS_PATCHING,
   APPOINTMENT_PATCH_FAILED,
   APPOINTMENT_PATCH_SUCCESS
  } from "../types/appointmentTypes";
  
  var initialState = {
    appointmentSaved: false,
    appointmentIsSaving: false,
    appointmentIsFetching: false,
    appointmentUpdated: false,
    appointmentIsUpdating: false,
    appointmentPatched: false,
    appointmentIsPatching: false,
    appointmentDeleted: false,
    appointmentIsDeleting: false,
    error: null,
    appointments: [],
    oneAppointment:{}
  };
  
  const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        //-------Save-------//
        case APPOINTMENT_IS_SAVING:
          return {
            ...state,
            appointmentIsSaving: true,
            error: null
          };
        case APPOINTMENT_SAVE_SUCCESS:
          return {
            ...state,
            appointmentSaved: true,
            appointmentIsSaving: false,
            error: null
          };
        case APPOINTMENT_SAVE_FAILED:
          return {
            ...state,
            appointmentIsSaving: false,
            error: action.payload
          };
    
        //-------Fetch-------//
        case APPOINTMENT_IS_FETCHING:
          return { ...state,appointmentIsFetching: true };
        case APPOINTMENT_FETCH_SUCCESS:
          return {
            ...state,
            appointmentIsFetching: false,
            appointments: action.payload,
            error: null
          };
          case ONE_APPOINTMENT_FETCH_SUCCESS:
          return {
            ...state,
            appointmentIsFetching: false,
            oneAppointment: action.payload,
            error: null
          };
        case APPOINTMENT_FETCH_FAILED:
          return {
            ...state,
            appointmentIsFetching: false,
            error: action.payload
          };
    
        
          //-------patch-------//
        case APPOINTMENT_IS_PATCHING:
          return { ...state, appointmentIsPatching: true,error: null };
        case APPOINTMENT_PATCH_SUCCESS:
          return {
            ...state,
            appointmentPatched: true,
            appointmentIsPatching: false,
            error: null
          };
        case APPOINTMENT_PATCH_FAILED:
          return {
            ...state,
            appointmentIsPatching: false,
            error: action.payload
          };
          //-------Delete-------//
      case APPOINTMENT_IS_DELETING:
        return { ...state, appointmentIsDeleting: true,error: null };
      case APPOINTMENT_DELETE_SUCCESS:
        return {
          ...state,
          appointmentDeleted: true,
          appointmentIsDeleting: false,
          error: null
        };
      case APPOINTMENT_DELETE_FAILED:
        return {
          ...state,
          appointmentIsDeleting: false,
          error: action.payload
        };
    
      default:
        return state;
    }
  };
  export default appointmentReducer;
  
  
/*-----------Types of ABOUT actions--------*/

/*---SAVE----*/
export const ABOUT_SAVE_SUCCESS = "ABOUT_SAVE_SUCCESS";
export const ABOUT_SAVE_FAILED = "ABOUT_SAVE_FAILED";
export const ABOUT_IS_SAVING = "ABOUT_IS_SAVING";
/*---FETCH----*/
export const ABOUT_FETCH_SUCCESS = "ABOUT_FETCH_SUCCESS";
export const ABOUT_FETCH_FAILED = "ABOUT_FETCH_FAILED";
export const ABOUT_IS_FETCHING = "ABOUT_IS_FETCHING";
/*---UPDATE----*/
export const ABOUT_UPDATE_SUCCESS = "ABOUT_UPDATE_SUCCESS";
export const ABOUT_UPDATE_FAILED = "ABOUT_UPDATE_FAILED";
export const ABOUT_IS_UPDATING = "ABOUT_IS_UPDATING";

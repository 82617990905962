import {
  SMS_SEND_SUCCESS,
  SMS_SEND_FAILED,
  SMS_IS_SENDING
} from "../types/smsTypes";

var initialState = {
  smsSent: false,
  smsIsSending: false,
  sms: {},
  error: null
};

const smsReducer = (state = initialState, action) => {
  switch (action.type) {
    //-------Send SMS-------//
    case SMS_IS_SENDING:
      return { ...state, smsIsSending: true };
    case SMS_SEND_SUCCESS:
      return {
        ...state,
        smsSent: true,
        smsIsSending: false,
        error: null
      };
    case SMS_SEND_FAILED:
      return {
        ...state,
        smsIsSending: false,
        error: action.payload
      };

    //----DEFAULT----//
    default:
      return state;
  }
};
export default smsReducer;

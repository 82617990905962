import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import Loadable from "react-loadable";
import BeatLoader from "react-spinners/BeatLoader"



const loading = () => (
  <div className="animated fadeIn pt-3 text-center mx-auto" style={{marginTop:250}}><BeatLoader color="#067BE3" loading={true} size={12}/></div>

  );


//Page404
const Page404 = Loadable({
  loader: () => import("./pages/404"),
  loading
});


function App() {
 
  return (
    <HashRouter>
    <Switch>
    
         
          <Route exact path="/" name="Page 404" component={Page404} />
         
         
        </Switch>
    </HashRouter>
  );
}



export default  (App);

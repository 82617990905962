/*-----------Types of EVENT actions--------*/

/*---SAVE----*/
export const BOOK_SAVE_SUCCESS = "BOOK_SAVE_SUCCESS";
export const BOOK_SAVE_FAILED = "BOOK_SAVE_FAILED";
export const BOOK_IS_SAVING = "BOOK_IS_SAVING";
/*---FETCH----*/
export const BOOK_FETCH_SUCCESS = "BOOK_FETCH_SUCCESS";
export const BOOK_FETCH_FAILED = "BOOK_FETCH_FAILED";
export const BOOK_IS_FETCHING = "BOOK_IS_FETCHING";

/*---UPDATE----*/
export const BOOK_UPDATE_SUCCESS = "BOOK_UPDATE_SUCCESS";
export const BOOK_UPDATE_FAILED = "BOOK_UPDATE_FAILED";
export const BOOK_IS_UPDATING = "BOOK_IS_UPDATING";

/*---PATCH----*/
export const BOOK_PATCH_SUCCESS = "BOOK_PATCH_SUCCESS";
export const BOOK_PATCH_FAILED = "BOOK_PATCH_FAILED";
export const BOOK_IS_PATCHING = "BOOK_IS_PATCHING";


/*---DELETE----*/
export const BOOK_DELETE_SUCCESS = "BOOK_DELETE_SUCCESS";
export const BOOK_DELETE_FAILED = "BOOK_DELETE_FAILED";
export const BOOK_IS_DELETING = "BOOK_IS_DELETING";
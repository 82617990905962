
   var initialState = {
     open:false,
     openDelete:false,
     info:{},
     infoDelete:{}
     };
   
   const AlertReducer = (state = initialState, action) => {
     switch (action.type) {
       //-------Fetch-------//
       case "OPEN":
         return { ...state,open:true,info:action.payload};
        case "CLOSE":
            return { ...state,open:false};
            case "OPEN_DELETE":
         return { ...state,openDelete:true,infoDelete:action.payload};
        case "CLOSE_DELETE":
            return { ...state,openDelete:false};
       //----DEFAULT----//
       default:
         return state;
     }
   };
   export default AlertReducer;
   
import {
   //SAVE
   FILE_UPLOAD_FAILED,
   FILE_UPLOAD_SUCCESS,
   FILE_IS_UPLOADING,
   //LOAD
   FILE_FETCH_FAILED,
   FILE_FETCH_SUCCESS,
   ONE_FILE_FETCH_SUCCESS,
   FILE_IS_FETCHING,
   //PATCH
   FILE_IS_PATCHING,
   FILE_PATCH_FAILED,
   FILE_PATCH_SUCCESS,
   //DELETE
   FILE_DELETE_SUCCESS,
   FILE_DELETE_FAILED,
   FILE_IS_DELETING,
   FILE_UPLOAD_PROGRESS
   
  } from "../types/fileUploadTypes";
  
  var initialState = {
    fileUploaded: false,
    fileIsUploading: false,
    fileIsFetching: false,
    fileUpdated: false,
    fileIsUpdating: false,
    filePatched: false,
    fileIsPatching: false,
    fileDeleted: false,
    fileIsDeleting: false,
    error: null,
    files: [],
    oneFile:{},
    uploadProgress:0
  };
  
  const filesReducer = (state = initialState, action) => {
    switch (action.type) {
        //-------Save-------//
        case FILE_IS_UPLOADING:
          return {
            ...state,
           fileIsUploading: true,
            error: null
          };
        case FILE_UPLOAD_SUCCESS:
          return {
            ...state,
            uploadProgress: action.payload,
            fileIsUploading: true,
            error: null
          };
          case FILE_UPLOAD_PROGRESS:
          return {
            ...state,
            fileUploaded: true,
            fileIsUploading: false,
            error: null
          };
        case FILE_UPLOAD_FAILED:
          return {
            ...state,
            fileIsUploading: false,
            error: action.payload
          };
    
        //-------Fetch-------//
        case FILE_IS_FETCHING:
          return { ...state,fileIsFetching: true };
        case FILE_FETCH_SUCCESS:
          return {
            ...state,
            fileIsFetching: false,
            files: action.payload,
            error: null
          };
          case ONE_FILE_FETCH_SUCCESS:
          return {
            ...state,
            fileIsFetching: false,
            oneFile: action.payload,
            error: null
          };
        case FILE_FETCH_FAILED:
          return {
            ...state,
            fileIsFetching: false,
            error: action.payload
          };
    
        
          //-------patch-------//
        case FILE_IS_PATCHING:
          return { ...state, fileIsPatching: true,error: null };
        case FILE_PATCH_SUCCESS:
          return {
            ...state,
            filePatched: true,
            fileIsPatching: false,
            error: null
          };
        case FILE_PATCH_FAILED:
          return {
            ...state,
            fileIsPatching: false,
            error: action.payload
          };
          //-------Delete-------//
      case FILE_IS_DELETING:
        return { ...state, fileIsDeleting: true,error: null };
      case FILE_DELETE_SUCCESS:
        return {
          ...state,
          fileDeleted: true,
          fileIsDeleting: false,
          error: null
        };
      case FILE_DELETE_FAILED:
        return {
          ...state,
          fileIsDeleting: false,
          error: action.payload
        };
    
      default:
        return state;
    }
  };
  export default filesReducer;
  
  
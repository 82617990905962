/*-----------Types of SPONSOR actions--------*/

/*---SAVE----*/
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";
export const FILE_IS_UPLOADING = "FILE_IS_UPLOADING";
/*---FETCH----*/
export const FILE_FETCH_SUCCESS = "FILE_FETCH_SUCCESS";
export const ONE_FILE_FETCH_SUCCESS = "One_FILE_FETCH_SUCCESS";
export const FILE_FETCH_FAILED = "FILE_FETCH_FAILED";
export const FILE_IS_FETCHING = "FILE_IS_FETCHING";
/*---UPDATE----*/
export const FILE_UPDATE_SUCCESS = "FILE_UPDATE_SUCCESS";
export const FILE_UPDATE_FAILED = "FILE_UPDATE_FAILED";
export const FILE_IS_UPDATING = "FILE_IS_UPDATING";
/*---PATCH----*/
export const FILE_PATCH_SUCCESS = "FILE_PATCH_SUCCESS";
export const FILE_PATCH_FAILED = "FILE_PATCH_FAILED";
export const FILE_IS_PATCHING = "FILE_IS_PATCHING";


/*---DELETE----*/
export const FILE_DELETE_SUCCESS = "FILE_DELETE_SUCCESS";
export const FILE_DELETE_FAILED = "FILE_DELETE_FAILED";
export const FILE_IS_DELETING = "FILE_IS_DELETING";

export const FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGRESS";
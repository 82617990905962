/*-----------Types of Message actions--------*/
/*---SAVE----*/
export const MESSAGE_SAVE_SUCCESS = "MESSAGE_SAVE_SUCCESS";
export const MESSAGE_SAVE_FAILED = "MESSAGE_SAVE_FAILED";
export const MESSAGE_IS_SAVING = "MESSAGE_IS_SAVING";
/*---FETCH----*/
export const MESSAGE_FETCH_SUCCESS = "MESSAGE_FETCH_SUCCESS";
export const MESSAGE_FETCH_FAILED = "MESSAGE_FETCH_FAILED";
export const MESSAGE_IS_FETCHING = "MESSAGE_IS_FETCHING";
export const ONE_MESSAGE_FETCH_SUCCESS = "ONE_MESSAGE_FETCH_SUCCESS";
/*---UPDATE----*/
export const MESSAGE_UPDATE_SUCCESS = "MESSAGE_UPDATE_SUCCESS";
export const MESSAGE_UPDATE_FAILED = "MESSAGE_UPDATE_FAILED";
export const MESSAGE_IS_UPDATING = "MESSAGE_IS_UPDATING";
/*---DELETE----*/
export const MESSAGE_DELETE_SUCCESS = "MESSAGE_DELETE_SUCCESS";
export const MESSAGE_DELETE_FAILED = "MESSAGE_DELETE_FAILED";
export const MESSAGE_IS_DELETING = "MESSAGE_IS_DELETING";